
export function TransactionList({ transactions }) {

  return (
    <div className="mt-10 flex justify-center min-h-12">
      <ul className="divide-y divide-gray-200 w-5/6">
        <li className="relative bg-white py-2 px-4">Recent Transactions</li>
        {transactions?.map((tx) => (
          <li
            key={tx.hash}
            className="relative bg-white py-2 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
          >
            <div className="flex space-x-3">
              <div className="min-w-0 flex-1">
                <a
                  href={`https://ropsten.etherscan.io/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer"
                  className="block focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <label className="text-sm text-gray-500 truncate">{tx.hash}</label>
                </a>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

}