import { useState } from 'react';
import { ContractInput } from './ContractInput';

const initialInputs = {
  ipAddress: '',
  port: 0,
  username: '',
  password: ''
}

export function ContractForm({ update, isUpdatedDict }) {
  const [ inputs, setInputs ] = useState(initialInputs);
  const [ isUpdating, setIsUpdating ] = useState(false);
  const handleInputs = (e) => {
    e.preventDefault();

    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }

  const handleForm = (e) => {
    e.preventDefault();

    setIsUpdating(true);
  }

  return(
    <>
      <div className="space-y-6">
        <div className="flex flex-row justify-between">
          <ContractInput className="w-1/2" id="ipAddress" label="IP Address" handleInputs={handleInputs} isDisabled={isUpdatedDict['ipAddress']} />
          <button
            className="items-center self-end h-1/2 px-10 py-2 border border-transparent justify-center font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            onClick={() => update.ipAddress(inputs)}>Update</button>
        </div>

        <div className="flex flex-row justify-between">
          <ContractInput className="w-1/3" id="port" label="Port" handleInputs={handleInputs} isDisabled={isUpdatedDict['port']} />
          <button
            className="items-center self-end h-1/2 px-10 py-2 border border-transparent justify-center font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            onClick={() => update.port(inputs)}>Update</button>
        </div>

        <div className="flex flex-row justify-between">
          <ContractInput className="w-2/3" id="username" label="Username" handleInputs={handleInputs} isDisabled={isUpdatedDict['username']} />
          <button
            className="items-center self-end h-1/2 px-10 py-2 border border-transparent justify-center font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            onClick={() => update.username(inputs)}>Update</button>
        </div>

        <div className="flex flex-row justify-between">
          <ContractInput className="w-2/3" id="password" label="Password" handleInputs={handleInputs} isDisabled={isUpdatedDict['password']} />
          <button
            className="items-center self-end h-1/2 px-10 py-2 border border-transparent justify-center font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
            onClick={() => update.password(inputs)}>Update</button>
        </div>
      </div>
    </>
  );
}