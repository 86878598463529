
export function ContractInput({ id, label, className, handleInputs, isDisabled }) {

  return (
      <div className={className}>
        <label htmlFor="ipAddress" className="text-left px-2 block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="mt-1">
          <input
            onChange={handleInputs}
            id={id}
            name={id}
            type={id}
            required
            disabled={isDisabled}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:ring-2 focus:border-blue-500 sm:text-sm text-gray-700"
          />
        </div>
      </div>
  );
}