import React, { useState, useEffect } from 'react';
import './App.css';
import { HeaderBar } from './components/HeaderBar';
import { ContractForm } from './components/ContractForm';
import { TransactionList } from './components/TransactionList';
import { HASH_CONTRACT_ADDRESS, HASH_CONTRACT_ABI } from './web3.config';
const Web3 = require('web3');
const Contract = require('web3-eth-contract');

// Not-so-elegant workaround to get 
declare global {
  interface Window {
    ethereum: any;
  }
}
const initialIsUpdated = {
  ipAddress: false,
  port: false,
  username: false,
  password: false
}

function App() {
  const [ account, setAccount ] = useState('');
  const [ contract, setContract ] = useState(new Contract(HASH_CONTRACT_ABI));
  const [ isUpdated, setIsUpdated ] = useState(initialIsUpdated);
  const tx: any = [];
  const [ recentTransactions, setRecentTransactions ] = useState(tx);

  useEffect(() => {
    loadBlockchainData()
  }, []);

  const loadBlockchainData = async() => {
    if(window?.ethereum) {
      try {
        const accounts = await window?.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
        }
      }
    }

    const web3 = await new Web3(new Web3().givenProvider || 'https://localhost:7545');
    const contract = new web3.eth.Contract(HASH_CONTRACT_ABI, HASH_CONTRACT_ADDRESS);
    setContract(contract);
  };


  const handleUpdateIPAddress = async ({ ipAddress }) => {
    let receipt = await contract.methods.incomingHostname(ipAddress).send({ from: account });
    setIsUpdated({ ...isUpdated, ipAddress: true });
    setRecentTransactions([ ...recentTransactions, { index: receipt.transactionIndex, hash: receipt.transactionHash }  ]);
  }

  const handleUpdatePort = async ({ port }) => {
    let receipt = await contract.methods.incomingPort(port).send({ from: account });
    setIsUpdated({ ...isUpdated, port: true });
    setRecentTransactions([ ...recentTransactions, { index: receipt.transactionIndex, hash: receipt.transactionHash }  ]);
  }
  const handleUpdateUsername = async ({ username }) => {
    let receipt = await contract.methods.incomingUsername(username).send({ from: account });
    setIsUpdated({ ...isUpdated, username: true });
    setRecentTransactions([ ...recentTransactions, { index: receipt.transactionIndex, hash: receipt.transactionHash }  ]);
  }
  const handleUpdatePassword = async ({ password }) => {
    let receipt = await contract.methods.incomingPassword(password).send({ from: account });
    setIsUpdated({ ...isUpdated, password: true });
    setRecentTransactions([ ...recentTransactions, { index: receipt.transactionIndex, hash: receipt.transactionHash } ]);
  }


  const update = {
    ipAddress: handleUpdateIPAddress,
    port: handleUpdatePort,
    username: handleUpdateUsername,
    password: handleUpdatePassword
  };

  return (
    <>
      <HeaderBar />
      <div className="App flex flex-col justify-center h-full bg-gray-300">
        <div className="flex justify-center h-screen">
          <div className="bg-white shadow sm:rounded-lg w-2/3 border-gray-200 h-3/4 mt-28 lg:mt-36">
            <div className="px-4 sm:p-6">
              <label className="text-xl leading-6 font-medium text-gray-900">Account Address: </label>
              <label className="text-lg leading-6 font-medium text-gray-900">{account ? account : 'No Wallet Found'}</label>
              <div className="flex flex-row justify-center w-full">
                <div className="mt-5 py-10 w-5/6 text-sm text-gray-500">
                  <ContractForm
                    update={update}
                    isUpdatedDict={isUpdated} />
                </div>
              </div>

              { recentTransactions.length > 0 ? <TransactionList transactions={recentTransactions} /> : <></> }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
